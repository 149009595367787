import React from 'react';
// import './About.css'; // 如果有需要引入相关样式文件

const About = () => {
  return (
    <div className="about-tab">
      <h2>About Our Product</h2>
      <p>
        - How to use: Input a stock code and select a date, then click Analyze button <br />
        - Popular stocks: NVDA, META, AMZN, AAPL, QQQ... <br />
        - Our tool identifies price changes within a 2-week period from the current query date, making it ideal for short-term trend analysis. <br />
        - The model has an overall accuracy of around 85%, tested primarily on the top 100 most traded US stocks. <br />
        - We use extensive data and allow users to backtest the model's performance with historical data. <br />
        - For any product questions or business collaboration, please contact us at stock@airacle.tech.
      </p>
      <p>
        Details: <a href="https://ec4dm2lbbcs.larksuite.com/wiki/ARENwECKQiuzzjk28yuuWv1vsBc" target="_blank" rel="noopener noreferrer">User Manual</a>
      </p>
    </div>
  );
};

export default About;
