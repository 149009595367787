import React from "react";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <img alt={"logo"} src="https://stock-api.when2buy.ai/image/logo.png"
         style={{width: '36px', margin: '10px', padding: 0}}/>
    <p style={{fontSize: '14px', color: 'gray'}}>
      Provided by When2buy Team
    </p>
  </footer>
);

export default Footer;
