import StockSignalsDashboard from './StockSignalsDashboard'; // Ensure this import is present

import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import React, {useEffect, useState} from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import Loading from "../components/Loading";
import './StockRecommendationChart.css';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const StockRecommendationChart = () => {
  const [stockName, setStockName] = useState('NVDA');
  const [buyDate, setBuyDate] = useState('');
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [activeTab, setActiveTab] = useState('chart');
  const [sub, setSub] = useState(true);
  const [credits, setCredits] = useState({
    total: 110,
    permanent: 100,
    temp: 10
  });

  const {user, getAccessTokenSilently} = useAuth0();

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setBuyDate(today);
  }, []);

  // useEffect(() => {
  //   isSub().then((data)=>  {
  //     setSub(data)
  //   });
  // }, [])

  useEffect(() => {
    getCredit().then((data)=>  {
      setCredits(data)
    });
  }, [loading])

  const isDateValid = (date) => {
    const selectedDate = new Date(date);
    const minDate = new Date('2020-01-01');
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // const dayOfWeek = selectedDate.getUTCDay();
    // const isWeekend = dayOfWeek === 5 || dayOfWeek === 6;
    const isWeekend = false;
    if (isWeekend) {
      setError('Please select a weekday.');
      return false;
    } else if (selectedDate < minDate) {
      setError('Date must be after 2020-01-01.');
      return false;
    } else if (selectedDate > today) {
      setError('Date cannot be in the future.');
      return false;
    } else {
      setError('');
      return true;
    }
  };

  const isValidStock = async (stock) => {
    const url = `${API_BASE_URL}/api/validate_stock?stock_name=${stock}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.valid;
    } catch (error) {
      console.error('Error validating stock symbol:', error);
      return false;
    }
  };

  // const isSub = async () => {
  //   const token = await getAccessTokenSilently();
  //   try {
  //     const response = await fetch(`${API_BASE_URL}/api/is_sub`,
  //       {headers: {'Authorization': `Bearer ${token}`}});
  //     const data = await response.json();
  //     console.log(data);
  //     return data.sub;
  //   } catch (error) {
  //     console.error('Error validating stock symbol:', error);
  //     return false;
  //   }
  // };


  const getCredit = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${API_BASE_URL}/api/credit`,
      {headers: {'Authorization': `Bearer ${token}`}});
    return await response.json();
  };

  const handleAnalyze = async () => {
    if (!buyDate) {
      setError('Please select a buy date.');
      return;
    }

    if (!isDateValid(buyDate)) {
      return;
    }

    const isValid = await isValidStock(stockName);
    if (!isValid) {
      setError('Invalid stock symbol. Please enter a valid stock symbol, e.g., NVDA for Nvidia, AAPL for Apple Inc.');
      return;
    }

    setLoading(true);
    setError('');

    const token = await getAccessTokenSilently();
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/get_stock_analysis?stock_name=${stockName}&buy_date=${buyDate}`,
        {headers: {'Authorization': `Bearer ${token}`}}
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail);
      }
      const apiData = await response.json();
      const formattedData = apiData.data.map(item => ({
        ...item,
        close: Number(item.close.toFixed(2)),
        profit: item.profit !== null ? Number(item.profit.toFixed(2)) : null
      }));
      setData(formattedData);
      setAnalysisResult({
        Stock_Date: apiData.Stock_Date,
        Signal: apiData.Signal,
        BuyScore: apiData.BuyScore,
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getRecommendationText = (buyOrSell) => {
    if (buyOrSell === 1) return 'Buy';
    if (buyOrSell === 0) return 'Sell';
    return 'Neutral';
  };

  const getRecommendationColor = (buyOrSell) => {
    if (buyOrSell === 1) return 'green';
    if (buyOrSell === 0) return 'red';
    return 'grey';
  };

  const getYDomain = (data) => {
    if (data.length === 0) return [0, 'auto'];
    const prices = data.map(item => item.close);
    const min = Math.min(...prices);
    const max = Math.max(...prices);
    return [min * 0.95, max * 1.05]; // Adjust the range slightly to add padding
  };

  return (
    <div className="stock-chart">
      <div className="tab-container">
        <button
          className={`tab-button ${activeTab === 'chart' ? 'active' : ''}`}
          onClick={() => setActiveTab('chart')}
        >
          Stock Analysis
        </button>
        <button
          className={`tab-button ${activeTab === 'dashboard' ? 'active' : ''}`}
          onClick={() => setActiveTab('dashboard')}
        >
          Last Month Stock Signals
        </button>
      </div>

      {activeTab === 'chart' && (
        sub ?
          <div className="chart-tab">
            <p>Credits: total {credits.total}, permanent {credits.permanent}, temporary {credits.temp}&nbsp;&nbsp;
              <Tooltip title="Each time you use the stock analysis feature, 5 credits will be deducted. Upon registration, you will receive 100 permanent credits. Every day, 10 temporary credits are added, which will automatically expire the next day.">
                <QuestionCircleOutlined style={{ fontSize: '16px', color: '#1890ff' }} />
              </Tooltip>
            </p>
            <div className="input-container">
              <input
                type="text"
                value={stockName}
                onChange={(e) => setStockName(e.target.value)}
                placeholder="Stock symbol (e.g., NVDA)"
                className="input"
              />
              <input
                type="date"
                value={buyDate}
                onChange={(e) => setBuyDate(e.target.value)}
                className="input"
              />
              <button onClick={handleAnalyze} className="button" disabled={loading}>
                {loading ? 'Analyzing...' : 'Analyze Stock'}
              </button>
            </div>
            {error && <div className="error">{error}</div>}
            {loading && <div className="loading">Loading, it usually takes around 15 seconds</div>}
            {analysisResult && (
              <div className="analysis-result"
                   style={{backgroundColor: getRecommendationColor(analysisResult.Signal)}}>
                <h3>Analysis Result</h3>
                <p>Recommendation: {getRecommendationText(analysisResult.Signal)}</p>
                <p>Buy Score: {analysisResult.BuyScore}</p>
              </div>
            )}

            {data.length > 0 && (
              <div className="chart-container">
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={data} margin={{top: 5, right: 5, left: 5, bottom: 5}}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis
                      dataKey="date"
                      tick={{fontSize: 10}}
                      interval={'preserveStartEnd'}
                      tickFormatter={(value) => value}
                    />
                    <YAxis
                      yAxisId="left"
                      tick={{fontSize: 10}}
                      tickFormatter={(value) => `$${value.toFixed(2)}`}
                      domain={getYDomain(data)}
                    />
                    <YAxis
                      yAxisId="right"
                      orientation="right"
                      tick={{fontSize: 10}}
                      tickFormatter={(value) => `${value}%`}
                    />
                    <Tooltip/>
                    <Legend wrapperStyle={{fontSize: 10, paddingTop: 10}}/>
                    <Line type="monotone" dataKey="close" stroke="#8884d8" yAxisId="left" name="Stock Price"
                          dot={false}/>
                    <Line type="monotone" dataKey="profit" stroke="#82ca9d" yAxisId="right" name="Profit (%)"
                          dot={false}/>
                    <ReferenceLine
                      x={buyDate}
                      stroke="green"
                      label={{value: "Buy Date", fontSize: 10, position: 'top'}}
                      yAxisId="left"  // Specify the y-axis here
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
          </div> :
          <div className="chart-tab">
            {user.email_verified ?
              <stripe-buy-button
                client-reference-id={user.sub.replace("|", "__")}
                buy-button-id="buy_btn_1PmBZCEUKKyCFBFh2eysJzYx"
                publishable-key="pk_test_51NKYsPEUKKyCFBFh70bsfgeJUxSGUPOHHuevOGtBnyHe9oqlgCbWYZfEr573mwASnWKQwbgpKmYJhsxcRngWliku00STr7E5e5"
                customer-email={user.email}
              >
              </stripe-buy-button> :
              <div className="text-center">
                <h1>Please Verified Your Email First!</h1>
                <h3>{user.email}</h3>
              </div>
            }
          </div>
      )
      }
      {
        activeTab === 'dashboard' && (
          <div className="dashboard-tab">
            <StockSignalsDashboard/>
          </div>
        )
      }
    </div>
  )
    ;
};

export default withAuthenticationRequired(StockRecommendationChart, {
  onRedirecting: () => <Loading/>,
});