import React from "react";
import {useAuth0} from "@auth0/auth0-react";

const Hero = () => {
  const {isAuthenticated} = useAuth0();
  return (isAuthenticated ?
      <div className="text-center hero my-5">
        <h1>When2buy AI</h1>
        <p>A prototype of new search features, using the strength of our AI models to give you fast signals with clear
          and relevant sources.</p>
        <p>Now click on the <b>stock</b> menu to use it!</p>
      </div> :
      <div className="text-center hero my-5">
        <h1>When2buy AI</h1>
        <h2 className="mb-4">Welcome! Please log in.</h2>
      </div>
  )
};

export default Hero;
