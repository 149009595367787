import { useAuth0 } from "@auth0/auth0-react";
import { Container, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import axios from 'axios';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js/auto';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

const API_BASE_URL = process.env.REACT_APP_API_URL;

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, BarElement, Tooltip, Legend);

const StockSignalsDashboard = () => {
    const [stockCodes, setStockCodes] = useState([]);
    const [selectedStock, setSelectedStock] = useState('');
    const [stockData, setStockData] = useState([]);


    useEffect(() => {
        const fetchStockCodes = async () => {
            try {
                console.log("Fetching stock codes...", API_BASE_URL);
                const response = await axios.get(`${API_BASE_URL}/api/check_history_stocks`);
                setStockCodes(response.data.sort());
            } catch (error) {
                console.error("Error fetching stock codes", error);
            }
        };

        fetchStockCodes();
    }, []);

    const {getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (selectedStock) {
            const fetchStockData = async () => {
                const token = await getAccessTokenSilently();
                try {
                    const response = await axios.get(`${API_BASE_URL}/api/check_history_stocks/${selectedStock}`,
                      {headers: {'Authorization': `Bearer ${token}`}});
                    setStockData(response.data);
                    console.log()
                } catch (error) {
                    console.error("Error fetching stock data", error);
                }
            };

            fetchStockData();
        }
    }, [getAccessTokenSilently, selectedStock]);

    const handleStockChange = (event) => {
        setSelectedStock(event.target.value);
    };

    const getPointBackgroundColor = (data) => {
        if (data.BuyOrSell === 1) return 'green'; // 买入
        if (data.BuyOrSell === 0) return 'red'; // 卖出
        if (data.BuyOrSell === -1) return 'black'; // 中立
        return 'gray'; // 缺失/未预测的数据
    };

    const getPointStyle = (data) => {
        if (data.BuyOrSell === 1 || data.BuyOrSell === 0) return 'triangle';
        return 'circle';
    };

    const getPointRotation = (data) => {
        if (data.BuyOrSell === 1) return 0;
        if (data.BuyOrSell === 0) return 180;
        return 0;
    };

    const chartData = {
        labels: stockData.map((data) => data.date),
        datasets: [
            {
                type: 'line',
                label: 'Close Price',
                data: stockData.map((data) => data.close !== null ? data.close : 0), // 确保 data 是数值
                borderColor: 'blue',
                borderWidth: 2,
                fill: false,
                pointBackgroundColor: stockData.map((data) => getPointBackgroundColor(data)),
                pointStyle: stockData.map((data) => getPointStyle(data)),
                pointRotation: stockData.map((data) => getPointRotation(data)),
                pointRadius: stockData.map((data) => (data.BuyOrSell !== null || data.profit === null ? 8 : 4))
            },
            {
                type: 'bar',
                label: 'Buy Score',
                data: stockData.map((data) => data.BuyScore !== null ? data.BuyScore : 0), // 确保 data 是数值
                backgroundColor: 'rgba(255, 165, 0, 0.5)', // 透明度调整为50%
                yAxisID: 'y1',
                barThickness: 10,
                barPercentage: 0.5, // 调整柱子的宽度
                categoryPercentage: 0.5, // 调整柱子的宽度
            }
        ]
    };

    const chartOptions = {
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    generateLabels: (chart) => {
                        const original = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
                        return [
                            ...original,
                            {
                                text: 'Buy',
                                fillStyle: 'green',
                                pointStyle: 'triangle',
                            },
                            {
                                text: 'Sell',
                                fillStyle: 'red',
                                pointStyle: 'triangle',
                            },
                            {
                                text: 'Neutral',
                                fillStyle: 'black',
                                pointStyle: 'circle',
                            },
                            {
                                text: 'Missing',
                                fillStyle: 'gray',
                                pointStyle: 'circle',
                            }
                        ];
                    }
                }
            }
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
                min: 0,
                max: 1,
                ticks: {
                    beginAtZero: true,
                },
            }
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Stock Signals Dashboard
            </Typography>
            All signals our AI predicted in the past month.
            <FormControl fullWidth margin="normal">
                <InputLabel id="stock-select-label">Select Stock</InputLabel>
                <Select
                    labelId="stock-select-label"
                    value={selectedStock}
                    label="Select Stock"
                    onChange={handleStockChange}
                >
                    {stockCodes.map((stock) => (
                        <MenuItem key={stock} value={stock}>
                            {stock}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {stockData.length > 0 && (
                <Line data={chartData} options={chartOptions} />
            )}
        </Container>
    );
};

export default StockSignalsDashboard;
